.analyticsNetworksContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
}

.analyticsNetworksHeader {
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.analyticsNetworksHeader__title {
  display: flex;
  align-items: center;
  gap: 8px;
}
.analyticsNetworksHeader__title > p {
  color: #808080;
  font-size: 20px;
  font-weight: 500;
}
.analyticsNetworksHeader__title > p:hover {
  color: #808080;
  cursor: pointer;
  text-decoration: underline;
}

.analyticsNetworksHeader__title > div {
  display: flex;
  align-items: center;
  gap: 8px;
}
.analyticsNetworksHeader__title > div > p {
  color: #222;
  font-size: 20px;
  font-weight: 600;
}

.analyticsNetworksTabs {
  display: flex;
  gap: 4px;
}
.analyticsNetworksActiveTab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: white;
  border-radius: 12px 12px 0px 0px;
}

.analyticsNetworksActiveTabNet {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.analyticsNetworksActiveTabCreators {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.analyticsNetworksTab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 12px 12px 0px 0px;
  background: rgba(255, 255, 255, 0.5);
}
.analyticsNetworksContent {
  display: flex;
  background: white;
  padding: 16px;
  border-radius: 0px 24px 24px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  box-shadow: 0px 0px 32px 0px rgba(180, 180, 180, 0.2);
}

.analyticsNetworksContentInfoAvatar {
  cursor: pointer;
  display: flex;
  padding: 8px 16px 8px 8px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #eaedf2;
}
.analyticsNetworksContentInfoAvatarActive {
  background: #7250f8 !important;
}
.analyticsNetworksContentInfoAvatar > img {
  object-fit: cover;
  border-radius: 12px;
  width: 24px;
  height: 24px;
}
.analyticsNetworksContentInfoAvatarActive > img {
  border-radius: 8px !important;
}
.analyticsNetworksContentInfoAvatar > p {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.16px;
}
.analyticsNetworksContentInfoAvatarActive > p {
  color: #fff !important;
}

.analyticsNetworksContentInfoStats {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.analyticsNetworksContentInfoStatsAgency {
  flex: 2 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.analyticsNetworksContentInfoStats > label {
  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.analyticsNetworksContentInfoStats > p {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.analyticsNetworkContentButton {
  width: 100%;
  color: white;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #7250f8;
  border: none;
  outline: none;
}
.analyticsNetworkContentButton:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.analyticsNetworkContentButton > p {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.analyticsNetworkTags {
  display: flex;
  gap: 8px;
}

.analyticsNetworkTags > span {
  display: flex;
  color: #fff;
  padding: 4px 8px;
  border-radius: 100px;
  background: #7250f8;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.analyticsEmptyContent {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 12px;
  border-radius: 0px 24px 24px 24px;
  align-items: center;
  height: 100%;
  gap: 12px;
}
.analyticsEmptyContentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% - 64px);
  padding: 32px;
  align-items: center;
  gap: 16px;
  background: #eaedf2;
  border-radius: 12px;
}

.analyticsEmptyContentInfo > p {
  color: #808080;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;
}
.analyticsEmptyButton {
  outline: none;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #7250f8;
  color: #fff;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.analyticsEmptyButton:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.analyticsComingSoon {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: gray;
  color: #fff;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.analyticsNetworksDelete {
  transform: translate(-4px, 1px);
}

.cardDeleteNet {
  position: absolute;
  display: flex;
  align-items: center;
  color: #ea2a2a;
  line-height: 150%;
  letter-spacing: -0.16px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
  transform: translateX(-105px);
  z-index: 10;
}
.cardDeleteNet:hover {
  background: #eaedf2;
  cursor: pointer;
}

@media (max-width: 1440px) {
  .analyticsNetworksHeader {
    width: 100%;
  }
}
@media (max-width: 834px) {
  .analyticsNetworksHeader__title {
    align-self: flex-start;
  }
  .analyticsNetworksHeader {
    flex-direction: column;
    gap: 16px;
  }
}
