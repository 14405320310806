.emailVerificationContainer {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
  width: 100%;
}

.emailVerificationFormContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  transform: translateY(-80px);
}

.emailVerificationImageContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50%;
}

.emailVerificationImage {
  width: 110%;
  height: auto;
}

@media (max-width: 1024px) {
  .emailVerificationContainer {
    width: 100%;
  }
  .emailVerificationFormContainer {
    width: 100%;
    transform: translateY(-80px);
  }
  .emailVerificationImageContainer {
    display: none;
    width: 100%;
    margin-top: 16px;
  }
}
@media (min-width: 1280) {
  .emailVerificationImage {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 1440px) {
  .emailVerificationImage {
    width: 80%;
    height: auto;
  }
}
