.emptyStateContent {
    display: flex;
    min-height: 255px;
    flex-direction: column;
    background: white;
    border-radius: 0px 24px 24px 24px;
    align-items: center;
    height: max-content;
    gap: 12px;
  }
  .emptyStateContentInfo {
    display: flex;
    min-height: 255px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(100% - 64px);
    padding: 32px;
    align-items: center;
    gap: 16px;
    background: #eaedf2;
    border-radius: 12px;
  }
  
  .emptyStateContentInfo > p {
    color: #808080;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.14px;
  }