.tooltip {
  position: fixed;
  z-index: 50;
  font-size: 0.75rem;
  font-weight: 500;
  border-radius: 8px;
  color: #222;
  pointer-events: none;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background: white;
  padding: 8px;
  margin-top: 5px;
  width: 150px;
}
