.headerOutLandingContainer {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 16px 16px 0px 16px;
  padding: 32px 32px 0px 32px;
  border-radius: 32px 32px 0px 0px;
}

.headerOutLandingButton {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  border: none;
  border-radius: 8px;
  background: #7250f8;
  color: #fff;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headerOutLandingButton:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.headerOutContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 32px 32px 0px 32px;
  background-color: #eaedf2;
}
.headerOutIconContainer {
  cursor: pointer;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  gap: 8px;
}

@media (max-width: 490px) {
  .headerOutLandingContainer {
    padding: 16px 16px 0px 16px;
  }
  .headerOutLandingButton {
    font-size: 12px;
  }
  .headerOutIconContainer {
    padding: 0;
  }
}
