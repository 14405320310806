.headerInContainer {
  display: flex;
  align-items: center;
  height: auto;
  background-color: black;
  padding: 16px 32px;
  justify-content: space-between;
}

.headerInIconContainer {
  display: flex;
  align-items: center;
  height: 24px;
  cursor: pointer;
}
.headerInIconContainer img {
  width: 100%;
  height: auto;
}

.headerInMenuContainer {
  display: flex;
  color: white;
  align-items: center;
  gap: 24px;
}
.headerInSectionContainer {
  display: flex;
  gap: 24px;
  align-items: center;
}
.headerInSection {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.headerInSection > img {
  display: none;
}
.headerInSection > label:hover {
  cursor: pointer;
}

.headerInProfile {
  cursor: pointer;
}

.headerInDropdown {
  z-index: 100;
  border-radius: 8px;
  border: 1px solid, #cbd2e0;
  background: #fff;
  position: absolute;
  right: 32px;
  top: 48px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.headerInDropdownProfile {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  &:hover {
    background: #eaedf2;
    border-radius: 8px 8px 0px 0px;
  }
}
.headerInDropdownProfile > label {
  cursor: pointer;
}
.headerInDropdownLogout {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  &:hover {
    background: #eaedf2;
    border-radius: 0px 0px 8px 8px;
  }
}
.headerInDropdownLogout > label {
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .headerInSection > img {
    display: block;
  }
  .headerInSection > label {
    display: none;
  }
  .headerInSectionContainer {
    gap: 16px;
  }
  .headerInMenuContainer {
    gap: 16px;
  }
  .headerInContainer {
    padding: 16px 16px;
  }
  .headerInProfile {
    width: 32px;
  }
  .headerInDropdown {
    top: 56px;
  }
}
@media (max-width: 834px) {
  .headerInDropdown {
    right: 16px;
  }
  .headerInContainer {
    padding: 16px 16px;
  }
}
