.integrationContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 500px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.integrationTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.integrationTitleContainer > label {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.integrationParagraphContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
}

.integrationParagraphContainer > label {
  color: #808080;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.integrationButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: max-content;
}
.integrationButton {
  align-items: center;
  border-radius: 8px;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  gap: 8px;
  line-height: normal;
  padding: 12px 16px;
  width: 100%;
}
.integrationButton:hover {
  opacity: 0.8;
}
.integrationButtonYT {
  background-color: #ea2a2a;
  width: 100%;
}
.integrationButtonTK {
  background-color: #000000;
  width: 100%;
}

.integrationButtonYT > img {
  width: 20px;
  height: 20px;
}
.integrationButtonTK > img {
  width: 20px;
  height: 20px;
}
.integrationButtonIG > img {
  width: 20px;
  height: 20px;
}
.integrationButtonIG {
  background: linear-gradient(
    30deg,
    #fd5 -5.78%,
    #ff543e 47.48%,
    #c837ab 100.75%
  );
}

.skipButton {
  color: #4a1ff6;
  font-size: 14px;
  width: max-content;
  margin-top: 16px;
}
.skipButton:hover {
  text-decoration-line: underline;
  cursor: pointer;
}
