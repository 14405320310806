.error {
  background-color: white;
  height: 100vh;
}

.error__container {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  padding: 0 16px 0 16px;
}

.error__title {
  max-width: 663px;
  color: #000;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}
.error__text {
  max-width: 663px;
  color: #808080;
  text-align: center;
  font-size: 16px;
}
.error__button {
  height: 48px;
  width: 180px;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  background-color: #7250f8;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.error__button:hover {
  cursor: pointer;
  background: #4a1ff6;
}

@media (max-width: 490px) {
  .error__container {
    gap: 32px;
  }
  .error__title {
    font-size: 28px;
  }
  .error__text {
    font-size: 12px;
  }
  .error__container > img {
    width: 80%;
    height: auto;
  }
}
