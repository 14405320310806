.analyticsNetworksHeader__timeSelector {
  display: flex;
  align-items: center;
  gap: 12px;
}

.analyticsNetworksHeader__timeSelectorActive {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  border-radius: 8px;
  background: #7250f8;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.analyticsNetworksHeader__timeSelectorDisabled {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  color: #b0b0b0;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
