.analyticsChartsContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  max-width: 1440px;
  @media (max-width: 834px) {
  }
}
.analyticsChartsTabs {
  display: flex;
  gap: 4px;
}
.analyticsChartsActiveTab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: white;
  border-radius: 12px 12px 0px 0px;
}
.analyticsChartsActiveTab > p {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.analyticsChartsContent {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 12px;
  border-radius: 0px 24px 24px 24px;
  align-items: center;
  gap: 12px;
}

.analyticsChartsContentBars, .analyticsChartsContentPie {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 12px;
}


.analyticsChartsContentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 32px;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
}

@media (max-width: 834px) {
  .analyticsChartsContentBars, .analyticsChartsContentPie {
    flex-direction: column;
  }

}

/* .analyticsChartsContentInfo > p {
  color: #808080;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;
} */
