.profileAnalyticContent {
  display: flex;
  min-height: 255px;
  flex-direction: column;
  background: white;
  padding: 12px;
  border-radius: 0px 24px 24px 24px;
  align-items: center;
  height: max-content;
  gap: 12px;
  @media (max-width: 834px) {
    height: 100%;
  }
}

.profileAnalyticButton {
  width: 100%;
  color: white;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #7250f8;
  border: none;
  outline: none;
}
.profileAnalyticButton:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.profileAnalyticButton > p {
  font-size: 14px;
  font-weight: 500;
}

.profileAnalyticsContentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% - 64px);
  padding: 32px;
  align-items: center;
  gap: 16px;
  background: #eaedf2;
  border-radius: 12px;
}

.profileAnalyticsContentInfo > p {
  color: #808080;
  text-align: center;
  font-size: 12px;
}
