/* Card.css */
.card {
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  background: white;
}

.card-header {
  display: flex;
  align-items: center;
  padding: 8px 32px;
  border-bottom: 1px solid #e5e7eb;
  gap: 8px;
}

.card-title {
  color: #222;
  font-weight: 600;
  font-size: 16px;
}

.card-Info {
  padding-top: 6px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card-content {
  padding: 24px;
  height: fit-content;
}

.card-footer {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  color: #393939;
  font-size: 12px;
  padding: 16px 32px;
}
.card-footer > div {
  padding-right: 40px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.card-footer > div > div {
  width: 21px;
  height: 8px;
}

@media (max-width: 490px) {
  .card-content {
    padding: 16px;
  }
}
