.privacyPageContainer {
  background: #222222;
}

.privacyContainer {
  margin: 0px 16px 16px 16px;
  background-color: white;
  display: flex;
  padding: 48px 88px;
  flex-direction: column;
  gap: 16px;
  min-height: calc(100vh - 210px);
  border-radius: 0 0 32px 32px;
}
.privacyTitle {
  max-width: 1440px;
  color: #222;
  font-family: Poppins;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.privacyTitle > p > span {
  color: #4a1ff6;
}
.privacyContent {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 834px) {
  .privacyTitle {
    font-size: 36px;
  }

  .privacyContainer {
    padding: 32px;
  }
}
@media (max-width: 490px) {
  .privacyTitle {
    font-size: 26px;
  }
  .privacyContainer {
    padding: 16px;
  }
}
@media (min-width: 1612px) {
  .privacyContainer {
    align-items: center;
  }
  .privacyTitle {
    transform: translateX(-20.9rem);
  }
}
