.chart-tooltip {
  display: grid;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;
  gap: 4px
}

.chart-tooltip-label {
  font-weight: 600;
  color: #333333;
}

.chart-tooltip-items {
  display: flex;
  flex-direction: column;
}

.chart-tooltip-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.chart-tooltip-indicator {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

.chart-tooltip-indicator.line {
  width: 2px;
  height: 100%;
  border-radius: 0;
}

.chart-tooltip-indicator.dashed {
  width: 0;
  border: 1px dashed currentColor;
}

.chart-tooltip-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}

.chart-tooltip-name {
  color: #666666;
}

.chart-tooltip-value {
  font-weight: 500;
  color: #666666;
}
