.profileNetworksContainer {
  transform: translateY(-149px);
  display: flex;
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
  @media (max-width: 834px) {
    transform: translateY(-52px);
  }
}
.profileNetworksTabs {
  display: flex;
  gap: 4px;
}
.profileNetworksActiveTab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: white;
  border-radius: 12px 12px 0px 0px;
}

.profileNetworksActiveTabNet {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profileNetworksActiveTabCreators {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.profileNetworksTab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 12px 12px 0px 0px;
  background: rgba(255, 255, 255, 0.5);
}
.profileNetworksContent {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 12px;
  border-radius: 0px 24px 24px 24px;
  align-items: center;

  gap: 12px;
}
.profileNetworksContentInfo {
  cursor: pointer;
  display: flex;
  width: calc(100% - 16px);
  padding: 8px;
  align-items: center;
  gap: 8px;
  background: #eaedf2;
  border-radius: 12px;
}
.profileNetworksContentInfoAvatar {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 12px;
}
.profileNetworksContentInfoAvatar > img {
  object-fit: cover;
  border-radius: 4px;
  width: 40px;
  height: 40px;
}
.profileNetworksContentInfoAvatar > p {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

.profileNetworksContentInfoStats {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profileNetworksContentInfoStatsAgency {
  flex: 2 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.profileNetworksContentInfoStats > label {
  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profileNetworksContentInfoStats > p {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.profileNetworkContentButton {
  width: 100%;
  color: white;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #7250f8;
  border: none;
  outline: none;
}
.profileNetworkContentButton:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.profileNetworkContentButton > p {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profileNetworkTags {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap; /* Evita que los elementos pasen a la siguiente línea */
  overflow: hidden; /* Oculta el contenido que no quepa */
  white-space: nowrap;
}

.profileNetworkTags > span {
  color: #fff;
  padding: 4px 8px;
  border-radius: 100px;
  background: #7250f8;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileEmptyContent {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 12px;
  border-radius: 0px 24px 24px 24px;
  align-items: center;
  height: 100%;
  gap: 12px;
}
.profileEmptyContentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% - 64px);
  padding: 32px;
  align-items: center;
  gap: 16px;
  background: #eaedf2;
  border-radius: 12px;
}

.profileEmptyContentInfo > p {
  color: #808080;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;
}
.profileEmptyButton {
  outline: none;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #7250f8;
  color: #fff;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profileEmptyButton:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.profileComingSoon {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: gray;
  color: #fff;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profileNetworksDelete {
  transform: translate(-4px, 1px);
}

.cardDeleteNet {
  position: absolute;
  display: flex;
  align-items: center;
  color: #ea2a2a;
  line-height: 150%;
  letter-spacing: -0.16px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
  transform: translateX(-105px);
  z-index: 10;
}
.cardDeleteNet:hover {
  background: #eaedf2;
  cursor: pointer;
}
