.howItWorks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}
.section__creators {
  padding: 16px;
  max-width: 1440px;
}
.section__creatorsTitleContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.section__creatorsTitle > p {
  color: #fff;
  font-family: Poppins;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.section__creatorsTitle > p > span {
  color: #7250f8;
}
.section__creatorsDescription {
  color: #8894ab;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.section__creatorsSection {
  background: white;
  display: flex;
  padding: 48px 88px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  border-radius: 48px;
}
.section__creatorsSectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
}

.text__creatorsSection1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2 1 auto;
}
.text__creatorsSection1 > p:first-child {
  width: 100%;
  color: #7250f8;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text__creatorsSection1 > p:last-child {
  width: 85%;
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img__creatorsSection1 {
  flex: 1 1 auto;
}
.text__creatorsSection2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2 1 auto;
  text-align: end;
}
.text__creatorsSection2 > p:first-child {
  width: 100%;
  color: #7250f8;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text__creatorsSection2 > p:last-child {
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img__creatorsSection2 {
  flex: 1 1 auto;
}
.text__creatorsSection3 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2 1 auto;
}
.text__creatorsSection3 > p:first-child {
  width: 100%;
  color: #7250f8;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text__creatorsSection3 > p:last-child {
  width: 85%;
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img__creatorsSection3 {
  flex: 1 1 auto;
}
.section__agency {
  padding: 16px;
  max-width: 1440px;
}
.section__agencyTitleContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.section__agencyTitle > p {
  color: #fff;
  font-family: Poppins;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.section__agencyTitle > p > span {
  color: #7250f8;
}
.section__agencyDescription {
  color: #8894ab;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.section__agencySection {
  background: white;
  display: flex;
  padding: 48px 88px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  border-radius: 48px;
}
.section__agencySectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
}

.text__agencySection1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2 1 auto;
}
.text__agencySection1 > p:first-child {
  width: 100%;
  color: #7250f8;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text__agencySection1 > p:last-child {
  width: 85%;
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img__agencySection1 {
  flex: 1 1 auto;
}
.text__agencySection2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2 1 auto;
  text-align: end;
}
.text__agencySection2 > p:first-child {
  width: 100%;
  color: #7250f8;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text__agencySection2 > p:last-child {
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img__agencySection2 {
  flex: 1 1 auto;
}
.text__agencySection3 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2 1 auto;
}
.text__agencySection3 > p:first-child {
  width: 100%;
  color: #7250f8;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text__agencySection3 > p:last-child {
  width: 85%;
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img__agencySection3 {
  flex: 1 1 auto;
}
.section__advertiser {
  padding: 16px;
  max-width: 1440px;
}
.section__advertiserTitleContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.section__advertiserTitle > p {
  color: #fff;
  font-family: Poppins;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.section__advertiserTitle > p > span {
  color: #7250f8;
}
.section__advertiserDescription {
  color: #8894ab;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.section__advertiserSection {
  background: white;
  display: flex;
  padding: 48px 88px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  border-radius: 48px;
}
.section__advertiserSectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
}

.text__advertiserSection1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2 1 auto;
}
.text__advertiserSection1 > p:first-child {
  width: 100%;
  color: #7250f8;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text__advertiserSection1 > p:last-child {
  width: 85%;
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img__advertiserSection1 {
  flex: 1 1 auto;
}
.text__advertiserSection2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2 1 auto;
  text-align: end;
}
.text__advertiserSection2 > p:first-child {
  width: 100%;
  color: #7250f8;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text__advertiserSection2 > p:last-child {
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img__advertiserSection2 {
  flex: 1 1 auto;
}
.text__advertiserSection3 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2 1 auto;
}
.text__advertiserSection3 > p:first-child {
  width: 100%;
  color: #7250f8;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text__advertiserSection3 > p:last-child {
  width: 85%;
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img__advertiserSection3 {
  flex: 1 1 auto;
}

@media (max-width: 1024px) {
  .section__creatorsTitle > p,
  .section__creatorsTitle > p > span,
  .section__advertiserTitle > p,
  .section__advertiserTitle > p > span,
  .section__agencyTitle > p,
  .section__agencyTitle > p > span {
    font-size: 48px;
  }

  .section__creatorsDescription,
  .section__advertiserDescription,
  .section__agencyDescription {
    font-size: 16px;
  }

  .section__creatorsSection,
  .section__advertiserSection,
  .section__agencySection {
    padding: 24px 24px;
    gap: 48px;
  }

  .section__creatorsSectionContainer,
  .section__advertiserSectionContainer,
  .section__agencySectionContainer {
    flex-direction: column;
    gap: 24px;
  }
  .section__creatorsSectionContainer:nth-of-type(2),
  .section__advertiserSectionContainer:nth-of-type(2),
  .section__agencySectionContainer:nth-of-type(2) {
    flex-direction: column-reverse;
  }
  .text__creatorsSection1 > p:first-child,
  .text__creatorsSection2 > p:first-child,
  .text__creatorsSection3 > p:first-child,
  .text__advertiserSection1 > p:first-child,
  .text__advertiserSection2 > p:first-child,
  .text__advertiserSection3 > p:first-child,
  .text__agencySection1 > p:first-child,
  .text__agencySection2 > p:first-child,
  .text__agencySection3 > p:first-child {
    width: fit-content;
    font-size: 24px;
  }
  .text__creatorsSection1 > p:last-child,
  .text__creatorsSection2 > p:last-child,
  .text__creatorsSection3 > p:last-child,
  .text__advertiserSection1 > p:last-child,
  .text__advertiserSection2 > p:last-child,
  .text__advertiserSection3 > p:last-child,
  .text__agencySection1 > p:last-child,
  .text__agencySection2 > p:last-child,
  .text__agencySection3 > p:last-child {
    width: fit-content;
  }
  .text__creatorsSection2,
  .text__advertiserSection2,
  .text__agencySection2 {
    text-align: start;
  }
}

@media (max-width: 490px) {
  .section__creatorsTitle > p,
  .section__creatorsTitle > p > span,
  .section__advertiserTitle > p,
  .section__advertiserTitle > p > span,
  .section__agencyTitle > p,
  .section__agencyTitle > p > span {
    font-size: 36px;
  }
  .img__creatorsSection1 > img,
  .img__creatorsSection2 > img,
  .img__creatorsSection3 > img,
  .img__agencySection1 > img,
  .img__agencySection2 > img,
  .img__agencySection3 > img,
  .img__advertiserSection1 > img,
  .img__advertiserSection2 > img,
  .img__advertiserSection3 > img {
    width: 90%;
    height: auto;
  }
}
